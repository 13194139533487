/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import SectionTitle from "../components/SectionTitle";
import PageContainer from "../components/PageContainer";
import ExternalLink from "../components/ExternalLink";

export const ContactPageTemplate = ({ title, subheading, contact }) => {
  return (
    <PageContainer>
      <SectionTitle title={title}>{title}</SectionTitle>
      <p sx={{ textAlign: "center", maxWidth: "500px", mx: "auto", mt: 3 }}>
        {subheading}
      </p>
      <div
        sx={{
          display: "flex",
          flexDirection: ["column", "row"],
          flexWrap: "wrap",
          my: [5, 6],
          maxWidth: "1200px",
          mx: ["auto", 6, 9],
        }}
      >
        {contact?.map((contact) => {
          return (
            <div
              key={contact.name}
              sx={{
                pb: [4, 5],
                display: "flex",
                flexDirection: ["column", "row"],
                justifyContent: "center",
                width: "100%",
              }}
            >
              <section
                sx={{
                  flex: 1,
                  mb: [5, 0],
                  textAlign: ["center", "left"],
                  mx: "auto",
                }}
              >
                <h3 sx={{ fontSize: 3, mb: 2 }}>{contact.name}</h3>
                <p>{contact.address}</p>
                <p>{contact.city}</p>
                <div sx={{ display: "block" }}>
                  <ExternalLink url={`mailto:${contact.email}`} color={"#333"}>
                    {contact.email}
                  </ExternalLink>
                </div>
                <div sx={{ display: "block" }}>
                  <ExternalLink url={`tel:${contact.phone}`} color={"#333"}>
                    {contact.phone}
                  </ExternalLink>
                </div>
              </section>
              <section sx={{ flex: [1, 2] }}>
                <iframe
                  title="Haha studio address "
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2035.1613119648628!2d18.043715751717794!3d59.33026061789988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465f9d6271764d57%3A0xab49fb901933f1cb!2sPipersgatan%2014%2C%20112%2024%20Stockholm!5e0!3m2!1sen!2sse!4v1594220615950!5m2!1sen!2sse"
                  frameBorder="0"
                  sx={{
                    border: 0,
                    width: "100%",
                    height: ["300px", null, "400px"],
                  }}
                  allowFullScreen=""
                  aria-hidden="false"
                  tabIndex="0"
                ></iframe>
              </section>
            </div>
          );
        })}
      </div>
    </PageContainer>
  );
};

ContactPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
};

const ContactPage = ({ data }) => {
  const { markdownRemark: post } = data;
  return (
    <Layout>
      <ContactPageTemplate
        title={post.frontmatter.title}
        subheading={post.frontmatter.subheading}
        contact={post.frontmatter.contact}
      />
    </Layout>
  );
};

ContactPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ContactPage;

export const contactPageQuery = graphql`
  query ContactPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "contact-page" } }) {
      id
      frontmatter {
        title
        subheading
        contact {
          address
          city
          email
          name
          phone
        }
      }
    }
  }
`;
